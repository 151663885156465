import {
  Button,
  Col,
  DatePicker,
  Form,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import DownloadIcon from "./../../../../images/image_manage_docs/DownloadInvoice.svg";
import SuccessImage from "./../../../../images/SuccessNotiIcon.svg"

import "./ListDocument.css";

import { openNotificationSweetAlert, screenMobile } from "../../../../Function";
import { GetListTaxAgent } from "../../../../api/manageInforUser";
import { Container } from "@mui/material";
import { ChangeTaxDeclareApi, GetListInvoiceDone } from "../../../../api/listDocument";

import 'dayjs/locale/vi';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ModalExportListDocument from "./ModalExportListDocument";
import { GetInForCompanyApi } from "../../../../api/inforCompanyApi";
import { useSelector } from "react-redux";
const { Option } = Select;
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

// Thiết lập locale tiếng Việt cho dayjs
dayjs.locale('vi');
function ListDocument() {

  const [dataTaxAgent, setDataTaxAgent] = useState([])

  const [form] = Form.useForm();
  const [idCustomer, setIdCustomer] = useState(null)

  const [dataDetailCompany, setDataDetailCompany] = useState({})
  const [isOpenModalExport, setIsOpenModalExport] = useState(false)

  const [dataChangePeriod, setDataChangePeriod] = useState("")


  const [dataSource, setDataSource] = useState([]);

  const [loadingTable, setLoadingTable] = useState(false);
  const [yearDeclare, setYearDeclare] = useState(dayjs().year())
  const [pager, setPager] = useState({
    pageSize: 10,
    count: 0,
    current: 1,
  });

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const listInvalid = ["", undefined, null]
  const checkRoleAdminTax = userInfo.role_id === 4 || userInfo.role_id === 3
  const checkIdCustomer = !checkRoleAdminTax ? userInfo.customer_id : idCustomer

  const customColorStatus = (text) => {
    if (text.toLowerCase() === "thất bại") {
      return { color: "#E55353", background: "rgba(229, 83, 83, 0.13)" };
    } else if (text.toLowerCase() === "đang xử lý") {
      return { color: "#FAAB35", background: "rgba(250, 171, 53, 0.17)" };
    } else if (text.toLowerCase() === "hoàn thành") {
      return { color: "#2EB85C", background: "rgba(87, 232, 135, 0.15)" };
    } else if (text.toLowerCase() === "đang định khoản") {
      return { color: "#4873DE", background: "rgba(72, 115, 222, 0.13)" };
    }
  };

  const formatQuarter = (value, type, year) => {
    return `${type} ${value} - ${year}`;
  }

  const changeValuePeriod = (e, type) => {
    let valuePeriod = ""
    if (listInvalid.includes(e)) {
      setDataChangePeriod("")
    } else {
      if (type === "quý") {
        valuePeriod = (e.$M / 3) + 1
      } else {
        valuePeriod = e.$M + 1
      }
      setDataChangePeriod(valuePeriod)
    }

  }

  const chooseTaxDeclare = (data) => {
    if (Number(data.tax_declare_period_type) === 0) {
      return <DatePicker
        placeholder="Chọn tháng"
        format={(value) => formatQuarter(value.month() + 1, "Tháng", value.year())}
        picker="month"
        onChange={(e) => changeValuePeriod(e, "tháng")}
        locale={{
          lang: {
            locale: "en_US",
            yearFormat: "YYYY",
            shortMonths: [
              "Tháng 1",
              "Tháng 2",
              "Tháng 3",
              "Tháng 4",
              "Tháng 5",
              "Tháng 6",
              "Tháng 7",
              "Tháng 8",
              "Tháng 9",
              "Tháng 10",
              "Tháng 11",
              "Tháng 12"
            ],
          }
        }}
      >
      </DatePicker>

    } else {
      return <DatePicker onChange={(e) => changeValuePeriod(e, "quý")} placeholder="Chọn Quý" format={(value) => formatQuarter(value.quarter(), "Quý", value.year())} picker="quarter"></DatePicker>
    }
  }


  const onFinish = (record) => {
    const valuePeriodDeclare = form.getFieldValue("period_declare")

    let FormData = require("form-data");
    let data = new FormData();
    data.append("id_customer", checkIdCustomer);
    data.append("id_invoice", record.id);
    data.append("tax_declare_year", valuePeriodDeclare.$y);
    data.append("tax_declare_period", dataChangePeriod);

    ChangeTaxDeclareApi(data).then(res => {
      form.resetFields()
      fetchInforInvoiceDone({
        id_customer: checkIdCustomer,
        page: pager.current,
        page_size: pager.pageSize,
        tax_declare_year: yearDeclare,
      });
      openNotificationSweetAlert(SuccessImage, res.data.message)
    }).catch(err => {
      console.log(err)
    })
  }

  const formEditTaxPeriod = (data) => {
    return (

      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        style={{ height: "auto" }}
      >
        <Form.Item
          name="period_declare"
          // label=<span className='label-form'>Kỳ kê khai</span>
          rules={[
            {
              required: true,
              message: "Vui lòng chọn kỳ kê khai"
            }
          ]}
        >
          {chooseTaxDeclare(data)}
        </Form.Item>
      </Form>
    )
  }

  const columnsMobile = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "name",
      align: "center",
      ellipsis: true,
      width: 30,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Thao tác",
      dataIndex: "thao_tac",
      key: "thao_tac",
      align: "center",
      ellipsis: true,
      width: 50,
    },
    {
      title: "Số hóa đơn",
      dataIndex: "invoice_number",
      key: "invoice_number",
      align: "center",
      ellipsis: true,
      width: 70,
    },

    {
      title: "Mã số thuế",
      dataIndex: "tax_code",
      key: "tax_code",
      align: "center",
      ellipsis: true,
      width: 80,
    },

    {
      title: "Thành tiền",
      dataIndex: "total_vat_amount",
      key: "total_vat_amount",
      align: "center",
      ellipsis: true,
      width: 80,
      render: (text, record) => text.toLocaleString(),
    },

    {
      title: "Định khoản",
      dataIndex: "name_status",
      key: "name_status",
      align: "center",
      ellipsis: true,
      width: 80,
      render: (text, record) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="text-dinh-khoan" style={customColorStatus(text)}>
              {text}
            </p>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "name",
      align: "center",
      ellipsis: true,
      width: 50,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Ngày HD",
      dataIndex: "invoice_date",
      key: "invoice_date",
      align: "center",
      ellipsis: true,
      render: (text, record) =>
        dayjs(text).format("DD-MM-YYYY") !== "Invalid Date"
          ? dayjs(text).format("DD-MM-YYYY")
          : null,
    },

    {
      title: "Kỳ kê khai",
      dataIndex: "tax_declare_full",
      key: "tax_declare_full",
      align: "center",
      ellipsis: true,
      render: (text, record) =>
        <Popconfirm
          title="Chỉnh sửa kỳ kê khai"
          // description={() => formEditTaxPeriod(record)}
          description={formEditTaxPeriod(record)}
          // open = {isOpenPopConfirm}
          okButtonProps={{
            htmlType: "button",
            onClick: () => onFinish(record),
            disabled: listInvalid.includes(dataChangePeriod)
          }}
          onVisibleChange={handleVisibleChange}
        // onConfirm={(e) => confirm(e, record)}
        // onCancel={cancel}
        // okText="Lưu"
        // cancelText="Hủy"
        // showCancel={false}

        >
          <Button >{text}</Button>
        </Popconfirm>
    },

    {
      title: "Loại HD",
      dataIndex: "type_document",
      key: "type_document",
      align: "center",
      ellipsis: true,
    },

    {
      title: "Tổng tiền chưa thuế",
      dataIndex: "total_money_without_vat",
      key: "total_money_without_vat",
      align: "center",
      ellipsis: true,
      // width: 120,
      render: (text, record) => text.toLocaleString(),
    },

    {
      title: "Tổng tiền thuế",
      dataIndex: "total_vat_money",
      key: "total_vat_money",
      align: "center",
      ellipsis: true,
      render: (text, record) => text.toLocaleString(),
    },

    {
      title: "Tổng tiền chiết khấu",
      dataIndex: "total_discount_amount",
      key: "total_discount_amount",
      align: "center",
      ellipsis: true,
      render: (text, record) => text.toLocaleString(),
      // width: 150,
    },

    {
      title: "Thành tiền",
      dataIndex: "total_vat_amount",
      key: "total_vat_amount",
      align: "center",
      ellipsis: true,
      // width: 150,
      render: (text, record) => text.toLocaleString(),
    },
  ];


  const handleVisibleChange = () => {
    setDataChangePeriod("")
    form.resetFields()
  }

  const fetchInforInvoiceDone = (params = {}) => {
    setLoadingTable(true);
    GetListInvoiceDone(params)
      .then((res) => {
        setPager({
          current: params.page,
          pageSize: params.page_size,
          count: res.data.count,
        });
        setLoadingTable(false);
        setDataSource(res.data.results);
      })
      .catch((err) => {
        setLoadingTable(false);
        console.log(err);
      });
  };

  const fetchListTaxAgent = () => {
    GetListTaxAgent().then(res => {
      const data = res.data.filter(item => item.id === res.data[0].id)
      setDataTaxAgent(res.data)
      chooseIdCustomer(res.data[0].id, data[0].name_organization)
    }).catch(err => {
      console.log(err)
    })
  }

  const fetchInforCompany = (id) => {
    GetInForCompanyApi(id).then(res => {
      setDataDetailCompany(res.data)
    }).catch(err => {
      console.error(err)
    })
  }

  //tax_declare_period_type = 0 // Tháng

  useEffect(() => {
    fetchListTaxAgent();
  }, []);

  useEffect(() => {
    setLoadingTable(true);
    const id = setInterval(() => {
      fetchInforInvoiceDone({
        id_customer: checkIdCustomer,
        page: 1,
        page_size: pager.pageSize,
        tax_declare_year: yearDeclare,
      });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }, [idCustomer, yearDeclare]);

  const downloadInvoiceAll = () => {
    setIsOpenModalExport(true)
  };

  const chooseIdCustomer = (value, name) => {
    setIdCustomer(value)
    fetchInforCompany(value)

    // fetchInforCompany(value)

  };

  const changeYearDeclare = (value) => {
    setYearDeclare(value.$y)
  }

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
    fetchInforInvoiceDone({
      id_customer: checkIdCustomer,
      page: pagination.current,
      page_size: pagination.pageSize,
      tax_declare_year: yearDeclare,
    });
  };

  return (
    <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1%", height: "88vh" }}>
      <Row style={{ paddingTop: 10 }}>
        {checkRoleAdminTax && dataTaxAgent.length > 0 &&

          <div style={{ display: 'flex', alignItems: "center" }}>
            <span className='text-choose-company'>DOANH NGHIỆP : </span>
            <Select
              size={"middle"}
              style={{ width: "230px", float: "left", marginLeft: "12px" }}
              placeholder="Chọn doanh nghiệp"
              showSearch
              className='choose-company'
              optionFilterProp="children" // phải có mới search đc
              onChange={chooseIdCustomer}
              defaultValue={dataTaxAgent[0].id}
            >
              {dataTaxAgent.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {item.name_organization}
                </Option>
              ))}
            </Select>
          </div>
        }
        <div style={{ display: 'flex', alignItems: "center", marginLeft: checkRoleAdminTax && dataTaxAgent.length > 0 && "2%" }}>
          <span className='text-choose-company'>NĂM KÊ KHAI : </span>

          <DatePicker allowClear={false} defaultValue={dayjs()} picker="year" onChange={changeYearDeclare} style={{ width: "230px", float: "left", marginLeft: "12px" }} />
        </div>
      </Row>

      <div style={{ width: "100%" }}>
        {/* {screenMobile === false ? ( */}
        <div style={{ width: "100%", position: "relative" }}>
          <Row style={{ paddingTop: "1%" }}>

            <Col span={16} offset={4} className="title-table-invoice-all">
              {"BẢNG KÊ"}
            </Col>
            <Col
              span={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                onClick={downloadInvoiceAll}
                className="btn-invoice-all"
                style={{
                  // marginRight: "3%",
                  background: "rgba(50, 31, 219, 0.12)",
                  border: "unset",
                }}
                title="Tải xuống các hóa đơn được tải lên trong 1 năm từ thời điểm hiện tại"
              >
                <img
                  alt=""
                  src={DownloadIcon}
                  style={{ marginRight: "3%" }}
                ></img>
                {screenMobile === false &&
                  <span style={{ color: "rgb(85,69,225)", fontWeight: "bold" }}>
                    TẢI XUỐNG
                  </span>
                }
              </Button>
            </Col>
          </Row>

          <Table
            // rowSelection = {rowSelection}
            size="small"
            columns={columns}
            dataSource={dataSource}
            onChange={handleChange}
            style={{ marginTop: "1%", fontSize: 14, position: 'absolute' }}
            className="table-invoice-all"
            bordered
            scroll={{
              // y: dataSource.length > 0 ? window.screen.availHeight >= 830 ? "60vh" : "60vh" : null,
              y: "60vh",
              // x: window.screen.availWidth * 1.5,
            }}
            loading={loadingTable}
            pagination={{
              current: pager.current,
              pageSize: pager.pageSize,
              total: pager.count,
              showSizeChanger: true,
              showTotal: (total, range) => `Tổng cộng: ${total}`,
              locale: {
                items_per_page: '/ trang'
              }
            }}
          />
        </div>
        {/* ) : (
          null
        )} */}
      </div>

      {isOpenModalExport &&
        <ModalExportListDocument
          open={isOpenModalExport}
          setIsOpenModalExport={setIsOpenModalExport}
          idCustomer={idCustomer}
          dataDetailCompany={dataDetailCompany}
          yearDeclare={yearDeclare}
        />
      }

    </Container>
  );
}

export default ListDocument;
