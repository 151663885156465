import { Button, Checkbox, Col, Collapse, Modal, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { localhost } from "../../../../server";
import { DownloadOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import BoxIcon from "../../../../images/image_manage_docs/BoxInforImage.svg";
import { DownloadInputInvoiceApi, GetDetailInvoiceApi } from "../../../../api/InvoiceApi";
import NoDataImage from "../../../../images/NoData.svg";
import dayjs from "dayjs";
import EditIcon from "./../../../../images/EditIcon.svg";
import CollapseEdit from "./CollapseEdit";
import XMLViewer from "react-xml-viewer";


const { createCanvas } = require("canvas");

const pdfjsLib = window.pdfjsLib;
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

function ModalViewDetail({
  isOpenModalDetail,
  setIsOpenModalDetail,
  idUser,
  idInvoice,
}) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numPagesPDF, setNumPagesPDF] = React.useState(1);
  const [urlPDF, setUrlPDF] = React.useState();
  const [imagesURL, setImagesURL] = React.useState([]);
  const [dataDetail, setDataDetail] = useState();
  const [loadingImg, setLoadingImg] = React.useState(false);
  const [imageDiffPDF, setImageDiffPDF] = useState();
  const [isOpenFormEdit, setIsOpenFormEdit] = useState(false);
  const [xmlURL, setXmlURL] = React.useState([]);

  const fetchDataDetail = () => {
    GetDetailInvoiceApi({
      id_customer: idUser,
      id_invoice: idInvoice,
    })
      .then((res) => {
        setDataDetail(res.data);
        const imgDiffPDF = res.data.file_invoice[0].name.slice(-3);
        if (imgDiffPDF.toLowerCase() !== "pdf") {
          setLoadingImg(false);
          setImageDiffPDF(res.data.file_invoice[0].path);
        } else {
          setUrlPDF(res.data.file_invoice[0].path);
          setNumPagesPDF((item) => item + 1)
        }

        if (
          res.data.result_path_xml !== "" &&
          res.data.result_path_xml !== undefined &&
          res.data.result_path_xml !== null
        ) {
          setXmlURL([res.data.result_path_xml])
        }
      })
      .catch((err) => {
        setLoadingImg(false);
      });
  };

  const handleNextPage = () => {
    if (currentPage < numPagesPDF + xmlURL.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function dataURLtoFile(dataURL, fileName) {
    let arr = dataURL.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  useEffect(() => {
    if (isOpenFormEdit === false) {
      fetchDataDetail();
    }

  }, []);

  useEffect(() => {
    setLoadingImg(true);
    if (urlPDF !== undefined) {
      const convertToImage = async () => {
        try {
          // Tạo đối tượng PDF từ dữ liệu PDF
          const pdf = await pdfjsLib.getDocument(localhost + "/" + urlPDF)
            .promise;

          // Lấy số trang của PDF
          const numPages = pdf.numPages;

          // Mảng chứa hình ảnh
          const images = [];
          const pageImagesURL = [];
          // Duyệt qua từng trang PDF
          for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            // Lấy trang PDF
            const page = await pdf.getPage(pageNumber);

            // Lấy kích thước của trang
            const viewport = page.getViewport({ scale: 2 });

            // Tạo canvas để vẽ trang PDF
            const canvas = createCanvas(viewport.width, viewport.height);
            const context = canvas.getContext("2d");
            // Render trang PDF lên canvas
            await page.render({
              canvasContext: context,
              viewport: viewport,
            }).promise;

            // Convert canvas thành hình ảnh dưới dạng URL
            const imageDataURL = canvas.toDataURL("image/png");
            const convert_base64tofileimg = dataURLtoFile(
              imageDataURL,
              `page_${pageNumber}__.png`
            );
            // Thêm hình ảnh vào mảng
            images.push(convert_base64tofileimg);
            pageImagesURL.push(URL.createObjectURL(convert_base64tofileimg));
          }
          setImagesURL(pageImagesURL);
          setLoadingImg(false);
          // Trả về mảng hình ảnh
          setNumPagesPDF(numPages);
          return images;
        } catch (error) {
          setLoadingImg(false);
          return [];
        }
      };
      convertToImage();
    }
  }, [urlPDF]);

  const handleCancel = () => {
    setIsOpenModalDetail(false);
  };

  const genExtra = () => {
    let count = 0;
    if (dataDetail !== undefined && parseInt(dataDetail.type_upload) !== 2) {
      dataDetail.data_criteria.forEach((item) => {
        if (dataDetail[item.name_abbreviation] === true) {
          count++;
        }
      });
      return (
        <span
          className={
            count === dataDetail.data_criteria.length
              ? "text-box-criteria-success"
              : "text-box-criteria-error"
          }
        >
          Đã đạt {count}/{dataDetail.data_criteria.length} tiêu chí
        </span>
      );
    }
  };

  const labelCollapse = (text) => {
    return (
      <span style={{ columnGap: 8 }}>
        <img alt="" src={BoxIcon}></img>
        {text}
      </span>
    );
  };

  const typeDocument = () => {
    if (dataDetail !== undefined && dataDetail !== null) {
      if (dataDetail.type_document === "HDMV") {
        return "(Mua vào)";
      } else if (dataDetail.type_document === "HDBR") {
        return "(Bán ra)";
      }
    }
    return "(Không xác định)"
  };

  const items = [
    {
      key: "1",
      label: labelCollapse(`Thông tin hóa đơn ${typeDocument()}`),
      children: <BoxInforInvoice dataDetail={dataDetail} />,
    },
    {
      key: "2",
      label: labelCollapse("Thông tin nhà cung cấp"),
      children: <BoxInforSupplier dataDetail={dataDetail} />,
    },
    {
      key: "3",
      label: labelCollapse("Thông tin khách hàng"),
      children: <BoxInforCustomer dataDetail={dataDetail} />,
    },
    {
      key: "4",
      label: labelCollapse("Kết quả kiểm tra"),
      children: <ResultCheck dataDetail={dataDetail} />,
      extra: genExtra(),
    },
    {
      key: "5",
      label: labelCollapse("Chi tiết sản phẩm"),
      children: <DetailProduct dataDetail={dataDetail} />,
    },
  ];

  const showFormEdit = () => {
    setIsOpenFormEdit(true);
  };

  const downloadInputInvoice = () => {
    // Tải thông tin đầu vào của HD dạng file zip: ảnh/bản thể hiện HD, file excel HD theo mẫu
    const fnFileDownload = require("js-file-download");
    const filename =
      "ExportInvoiceWithTemplate_" + dayjs(new Date()).format("DDMMYYYY") + ".xlsx";
    DownloadInputInvoiceApi({
      id_customer: idUser,
      id_invoice: idInvoice,
    })
      .then((res) => {
        fnFileDownload(res.data, filename);
      })
      .catch((err) => { });
  };

  const Controls = () => {
    const { resetTransform } = useControls();
    return (

      <Row
        style={{
          alignItems: "center",
          display: "flex",
          paddingBottom: "0.5%",
        }}
      >
        <Col span={7} style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => resetTransform()}
            className="btn-reset"
            icon={<IconResetSize />}
          ></Button>
        </Col>

        <Col
          span={9}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {numPagesPDF && (
            <span style={{ float: "unset" }}>
              {imagesURL.length > 0 || xmlURL.length > 0 ? (
                <>
                  <Button
                    // type="primary"
                    shape="round"
                    size="small"
                    // icon={<DoubleLeftOutlined style={{ color: "#516583" }} />}
                    icon={<IConPrePage />}
                    onClick={() => {
                      handlePreviousPage();
                      resetTransform();
                    }}
                    disabled={currentPage === 1}
                    className="btn-pagination"
                  >
                    {/* <img src={ArrowPre} alt=''></img> */}
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    // type="primary"
                    shape="round"
                    size="small"
                    icon={<IConNextPage />}
                    // icon={<DoubleRightOutlined style={{ color: "#516583" }} />}

                    onClick={() => {
                      handleNextPage();
                      resetTransform();
                    }}
                    disabled={currentPage === numPagesPDF + xmlURL.length}
                    className="btn-pagination"
                  >
                    {/* <img src={ArrowNext} alt=''></img> */}
                  </Button>
                </>
              ) : null}
            </span>
          )}
        </Col>
        <Col span={8} style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', columnGap: "1ch" }}>
          <span className="text-pagi" style={{ float: "right" }}>
            {currentPage}/{numPagesPDF + xmlURL.length}
          </span>
          <Button
            onClick={() => downloadInputInvoice()}
            className="btn-reset"
            style={{ textAlign: "end" }}
            icon={<DownloadOutlined />}
            title="Tải thông tin hóa đơn"
          ></Button>
        </Col>
      </Row>
    );
  };

  const antIcon = <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />;
  return (
    <Modal
      open={isOpenModalDetail}
      // onOk={handleOk}
      onCancel={handleCancel}
      width={"85%"}
      style={{ height: "60vh", top: 10 }}
      // closable={false}
      className="modal-detail"
      footer={false}
    // centered
    >
      <Row>
        <Col
          span={10}
          style={{
            height: "100%",
            width: "100%",
            // justifyContent: 'center',
            // alignItems: 'center'
          }}
        >
          {loadingImg === true ? (

            <div className="loading-detail">
              <Spin
                indicator={antIcon}
                spinning={loadingImg}
                size="large"
              ></Spin>
              {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description = "Ảnh trống"/> */}
            </div>

          ) : (
            <div style={{ position: "relative" }}>
              {/* dataDetail.type_upload === 2 là up bằng excel */}

              {dataDetail !== undefined &&
                parseInt(dataDetail.type_upload) !== 2 ? (

                <TransformWrapper>
                  {({ zoomIn, zoomOut, resetTransform }) => (
                    <>
                      <Controls />
                      {currentPage <= numPagesPDF ? (
                        <TransformComponent
                          contentStyle={{
                            cursor: "zoom-in",
                            width: "100%",
                            display: "flow",
                            padding: "1% 1% 1%",
                          }}
                        >
                          <img
                            src={
                              imageDiffPDF !== undefined
                                ? localhost +
                                "/" +
                                encodeURIComponent(imageDiffPDF)
                                : imagesURL[currentPage - 1]
                            }
                            style={{
                              width: "100%",
                              height: "87vh",
                              filter: "drop-shadow(2px 4px 6px black)",
                              imageRendering: "unset",
                            }}
                            alt="Hình ảnh"
                          />
                        </TransformComponent>
                      ) : (
                        xmlURL.length !== 0 && (
                          <div
                            style={{
                              width: "100%",
                              height: "87vh",
                              imageRendering: "unset",
                              overflowY: "auto",
                            }}
                          >
                            <XMLViewer xml={xmlURL[0]} />
                          </div>
                        )
                      )}
                    </>
                  )}
                </TransformWrapper>

              ) : (
                <div className="none-image">
                  {/* <Empty /> */}
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <img src={NoDataImage} alt=""></img>
                    <span style={{ margin: 0, color: "#00000087" }}>
                      không có dữ liệu để hiển thị
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </Col>
        <Col span={13} offset={1}>
          {isOpenFormEdit === false && dataDetail !== undefined ? (
            <>
              {dataDetail.status === "3" || dataDetail.status === "4" ? (
                <Button
                  onClick={showFormEdit}
                  // className="btn-invoice-all"
                  style={{
                    background: "rgba(5, 141, 244, 0.18)",
                    border: "unset",
                    height: 30,
                    padding: 4,
                    width: "fit-content"
                  }}
                >
                  <img alt="" src={EditIcon}></img>
                </Button>
              ) : (
                <>&nbsp;</>
              )}
              <Collapse
                className="collapse-desktop"
                expandIconPosition="end"
                size="small"
                items={items}
                defaultActiveKey={["1"]}
              />
            </>
          ) : (
            <CollapseEdit
              dataDetail={dataDetail}
              setIsOpenFormEdit={setIsOpenFormEdit}
              isOpenFormEdit={isOpenFormEdit}
              setLoadingImg={setLoadingImg}
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
}

const BoxInforInvoice = ({ dataDetail }) => {
  const formatDate = (text) => {
    if (text !== undefined && text !== null) {
      return dayjs(text).format("DD-MM-YYYY");
    } else {
      return null;
    }
  };
  return (
    <>
      {dataDetail && (
        <Row className="box-infor-invoice" style={{ rowGap: 8 }}>
          <Col span={12}>
            <Row>
              <Col span={10}>Mẫu số:</Col>
              <Col span={14}>{dataDetail.form}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              {/* invoice_signed_date DATE NULL, --Ngày ký HDDT */}
              <Col span={10}>Ngày ký:</Col>
              <Col span={14}>{formatDate(dataDetail.invoice_signed_date)}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={10}>Ký hiệu:</Col>
              <Col span={14}>{dataDetail.symbol}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              {/* invoice_date DATE NULL, --Ngay_HD */}
              <Col span={10}>Ngày phát hành:</Col>
              <Col span={14}>{formatDate(dataDetail.invoice_date)}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={10}>Số hóa đơn:</Col>
              <Col span={14}>{dataDetail.invoice_number}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              {/* created_date */}
              <Col span={10}>Ngày tải lên:</Col>
              <Col span={14}>{formatDate(dataDetail.created_date)}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={10}>Tổ chức sở hữu hóa đơn:</Col>
              <Col span={14}>{dataDetail.trader_organization.name}</Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

const BoxInforSupplier = ({ dataDetail }) => {
  return (
    <>
      {dataDetail && (
        <Row className="box-infor-supplier" style={{ rowGap: 8 }}>
          <Col span={24}>
            <Row>
              <Col span={4}>Tên:</Col>
              <Col span={18}>{dataDetail.invoice_seller.name_seller}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={4}>Mã số thuế:</Col>
              <Col span={18}>{dataDetail.invoice_seller.tax_code_seller}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={4}>Địa chỉ:</Col>
              <Col span={18}>{dataDetail.invoice_seller.address_seller}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={4}>Số điện thoại:</Col>
              <Col span={18}>{dataDetail.invoice_seller.phone_seller}</Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

const BoxInforCustomer = ({ dataDetail }) => {
  return (
    <>
      {dataDetail && (
        <Row className="box-infor-supplier" style={{ rowGap: 8 }}>
          <Col span={24}>
            <Row>
              <Col span={4}>Tên:</Col>
              <Col span={18}>{dataDetail.invoice_buyer.name_buyer}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={4}>Mã số thuế:</Col>
              <Col span={18}>{dataDetail.invoice_buyer.tax_code_buyer}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={4}>Địa chỉ:</Col>
              <Col span={18}>{dataDetail.invoice_buyer.address_buyer}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={4}>Số điện thoại:</Col>
              <Col span={18}>{dataDetail.invoice_buyer.phone_buyer}</Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

const ResultCheck = ({ dataDetail }) => {
  return (
    <>
      {dataDetail !== undefined && parseInt(dataDetail.type_upload) !== 2 ? (
        <Row className="box-result-criteria">
          {dataDetail.data_criteria.map((item) => {
            return (
              <Col span={12} key={item.id}>
                <Checkbox
                  checked={dataDetail[item.name_abbreviation]}
                ></Checkbox>{" "}
                <span
                  style={{
                    color:
                      dataDetail[item.name_abbreviation] !== true
                        ? "rgb(229, 83, 83)"
                        : "black",
                  }}
                >
                  {item.name_display}
                </span>
              </Col>
            );
          })}
        </Row>
      ) : null}
    </>
  );
};

const DetailProduct = ({ dataDetail }) => {
  const ConvertLocaleString = (text) => {
    if (text !== undefined && text !== null) {
      return text.toLocaleString();
    } else {
      return text;
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      width: 50,
      align: "center",
      render: (text, record, index) => text + 1,
    },
    {
      title: "Mặt hàng",
      dataIndex: "item_name",
      key: "item_name",
      width: 500,
      align: "center",
    },
    {
      title: "Đơn vị",
      dataIndex: "unit_name",
      key: "unit_name",
      width: 120,
      align: "center",
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      width: 120,
      align: "center",
      render: (text, record) => ConvertLocaleString(text),
    },
    {
      title: "Đơn giá",
      dataIndex: "unit_price",
      key: "unit_price",
      width: 120,
      align: "center",
      render: (text, record) => ConvertLocaleString(text),
    },
    {
      title: "Tiền hàng",
      dataIndex: "amount_without_vat",
      key: "amount_without_vat",
      width: 120,
      align: "center",
      render: (text, record) => ConvertLocaleString(text),
    },
    {
      title: "Tiền chiết khấu",
      dataIndex: "discount_amount",
      key: "discount_amount",
      align: "center",
      width: 200,
      render: (text, record) => ConvertLocaleString(text),
    },
    {
      title: "Thuế suất",
      dataIndex: "vat_percentage",
      key: "vat_percentage",
      width: 120,
      align: "center",
      render: (text, record) => text + "%",
    },
    {
      title: "Tiền thuế",
      dataIndex: "amount_vat",
      key: "amount_vat",
      width: 120,
      align: "center",
      render: (text, record) => ConvertLocaleString(text),
    },
  ];

  const showFooter = () => {
    return (
      <Row className="box-detail-product">
        <Col span={14}>Tổng tiền hàng:</Col>
        <Col span={10}>
          {ConvertLocaleString(dataDetail.total_money_without_vat)}
        </Col>
        <Col span={14}>Tổng tiền chiết khấu:</Col>
        <Col span={10}>
          {ConvertLocaleString(dataDetail.total_discount_amount)}
        </Col>
        <Col span={14}>Tổng thuế GTGT:</Col>
        <Col span={10}>{ConvertLocaleString(dataDetail.total_vat_money)}</Col>
        <Col span={14}>Tổng tiền thanh toán:</Col>
        <Col span={10}>
          {ConvertLocaleString(dataDetail.total_vat_amount)}
        </Col>
      </Row>
    );
  };

  return (
    <>
      {dataDetail && (
        <Table
          scroll={{
            x: 1000,
          }}
          size="small"
          dataSource={dataDetail.list_products}
          columns={columns}
          pagination={false}
          footer={() => showFooter()}
          className="table-detail-product"
        />
      )}
    </>
  );
};

const IConPrePage = () => {
  return (

    <span className="icon-next">
      <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.25 7L6.25 4L9.25 1"
          stroke="#061C3D"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 7L1 4L4 1"
          stroke="#061C3D"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>

  );
};
const IConNextPage = () => {
  return (

    <span className="icon-next">
      {/* chỉ sử được sử dụng thẻ svg */}
      <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7L4 4L1 1"
          stroke="#061C3D"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.25 7L9.25 4L6.25 1"
          stroke="#061C3D"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>

  );
};
const IconResetSize = () => {
  return (
    <span className="icon-next">
      {/* chỉ sử được sử dụng thẻ svg */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="16"
        viewBox="0 0 12 13"
        fill="none"
      >
        <path
          d="M7.67 2.51025C7.17 2.36025 6.62 2.26025 6 2.26025C3.24 2.26025 1 4.50025 1 7.26025C1 10.0203 3.24 12.2603 6 12.2603C8.76 12.2603 11 10.0203 11 7.26025C11 6.23025 10.69 5.28026 10.16 4.48026"
          stroke="#516583"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.37973 2.65024L6.71973 0.740234"
          stroke="#516583"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.38043 2.65039L6.44043 4.07039"
          stroke="#516583"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
};

export default ModalViewDetail;
